import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import BrandMain from "../../components/branding/brandMain"
import BrandingListing from "../../components/branding/brandingListing"
import BrandingWorkCategory from "../../components/branding/brandingWorkCategory"
import BrandingLogo from "../../components/branding/brandingLogo"
import BrandingAboutWork from "../../components/branding/brandingAboutWork"
import BrandTestimonials from "../../components/branding/brandTestimonials"
import ProjectInMind from "../../components/global/projectInMind"
import HomeForm from "../../components/home/homeForm"
import DirectContact from "../../components/global/directContact"

import bndfeaturedImage from "../../../static/home/wowmakers.jpeg"

import "../../sass/pages/branding.scss"
import "../../sass/global/work-category.scss"

export const Head = () => (
    <>
        <SeoHead
            ogImage={bndfeaturedImage}
            title="Where We Produce The Best Brand Identity Design | WowMakers"
            description="Looking for top-notch brand identity design? We specialize in creating compelling brand identities that elevate your business and engage your audience."
        />
    </>
)

const Branding = () => {
    return (
        <Layout>
            <Seo
                bclass="brand-identity-design"
                bid="brand-identity-design"
            ></Seo>
            <ScrollIndicationHeightZero />
            <BrandMain />
            <BrandingListing />
            <BrandingWorkCategory />
            <BrandingLogo />
            <BrandingAboutWork />
            <BrandTestimonials />
            <ProjectInMind title="Turn your vision into digital reality"/>
            <HomeForm page="branding"/>
            <DirectContact />
        </Layout>
    )
}

export default Branding
