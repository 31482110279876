import React from "react"
import MainLogo from "../global/mainLogo"
import ButtonIcon from "../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"

import "../../sass/global/main.scss"

const BrandMain = () => {
  return (
    <div className="main">
      <Container>
        <Row>
          <Col md={12} lg={7} xl={6}>
            <div className="intro">
              <p className="service-label">BRANDING</p>
              <h1 class="title">Where Your Story Becomes a Brand</h1>
              <p className="description">
                Every business has a unique story. We listen, understand, and transform your narrative into a powerful brand identity. Dive in to see how we blend strategy, creativity, and expertise to make your brand unforgettable.
              </p>
              <a href="/animated-explainer-video/#CQL" class="btn wowaction-btn drift-chat-popup">
                <ButtonIcon />
                <span>Let's Talk. Unlock Your Brand's Potential</span>
              </a>
            </div>
          </Col>
          <MainLogo />
        </Row>
      </Container>
    </div>
  )
}

export default BrandMain