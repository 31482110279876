import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const BrandingWorkCategory = () => {
  return (
    <section className="work-category-in-details branding">
      <Container>
        <Row className="portfolio-content-home">
          <Col md={12}>
            <h2>Our Branding Services</h2>
            <p>
              True branding goes beyond visuals. Our holistic approach transforms your core values into a living, breathing brand culture. Explore how our services shape every touchpoint of your brand experience
            </p>
            <Col md={12}>
              <div className="row">
                <Col sm={6}>
                  <h3>Brand Strategy</h3>
                  <ul className="item">
                    <li>Research & insights</li>
                    <li>Brand positioning</li>
                    <li>Brand personality</li>
                    <li>Define archetype</li>
                    <li>Brand architecture</li>
                    <li>Brand audit</li>
                    <li>Competitive analysis</li>
                  </ul>
                  
                </Col>
                <Col sm={6}>
                  <h3>Verbal Brand Identity</h3>
                  <ul className="item">
                    <li>Brand naming</li>
                    <li>Tagline</li>
                    <li>Brand messaging and voice crafting</li>
                    <li>Brand story development</li>
                    <li>Tone of voice guidelines</li>
                    <li>Copywriting for key brand touchpoints</li>
                  </ul>
                </Col>
                <Col sm={6}>
                  <h3>Visual Brand Identity</h3>
                  <ul className="item item-mb-desktop-none">
                    <li>Logo design</li>
                    <li>Color themes</li>
                    <li>Patterns and illustrations</li>
                    <li>Typography and Icons</li>
                    <li>Brand guideline</li>
                    <li>Brand identity system</li>
                  </ul>
                </Col>
                <Col sm={6}>
                  <h3>Brand Communication</h3>
                  <ul className="item item-mb-desktop-none item-bottom-m">
                    <li>Stationery design</li>
                    <li>Merchandise design</li>
                    <li>Brochure and posters</li>
                    <li>Signage and environmental graphics</li>
                    <li>Social media templates</li>
                    <li>Presentation design</li>
                  </ul>
                </Col>                
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BrandingWorkCategory
