import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../icon/buttonIcon"

const BrandingListing = () => {
  return (
    <section className="home-cs-listing branding-listing">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              Branding Success Stories
            </h2>
            <p className="sub-desc">
              Witness the power of strategic branding. Our portfolio spans 150+ projects across industries, each telling a unique business evolution and growth story.
            </p>
          </Col>
        </Row>
        <Row className="wrapper-main">
          <Col lg={4} className="mt-md-0 mt-3">
            <Link to="/branding/kalahari-bank/" className="home-cs-link first">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/kalahari/Kalahari-listing-img.png"
                  alt="Kalahari Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Banking & FinTech</span>
                <div className="cs-header">
                  <h3>
                    Kalahari Bank
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>                    
          <Col lg={4} className="mt-lg-0 mt-3">
            <Link to="/branding/beebom/" className="home-cs-link middle">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/beebom/beebom-brand-listing-img.png"
                  alt="Beebom Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Media</span>
                <div className="cs-header">
                  <h3>
                    Beebom
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-0 mt-3">
            <Link to="/branding/bloom-botanics/" className="home-cs-link last">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/bloom-botanics/bloom-botanics-listing-img.png"
                  alt="Bloom Botanics Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Health & Wellness</span>
                <div className="cs-header">
                  <h3>
                    Bloom Botanics
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/mayabae/" className="home-cs-link first">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/mayabae/mayabae-brand-listing.png"
                  alt="MayaBae Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>E-commerce</span>
                <div className="cs-header">
                  <h3>
                    MayaBae
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/dhara-ayur-essentials/" className="home-cs-link middle">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/dhara-ayur-essentials/dhara-ayur-essentials-Branding-listing-image.png"
                  alt="Dhara Ayur Essentials Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Health & Wellness</span>
                <div className="cs-header">
                  <h3>
                    Dhara Ayur Essentials
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/blue-mermaid-events/" className="home-cs-link last">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/blue-mermaid-events/blue-mermaid-events-brand-listing-img.png"
                  alt="Blue Mermaid Events Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Hospitality & Tourism</span>
                <div className="cs-header">
                  <h3>
                    Blue Mermaid Events
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/studio34/" className="home-cs-link first">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/studio-34/studio-34-listing-image.png"
                  alt="Studio 34 Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>IT/Consulting</span>
                <div className="cs-header">
                  <h3>
                    Studio 34
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/bekal-aquatics/" className="home-cs-link middle">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/bekal-aquatics/bekal-aquatics-listing-image.png"
                  alt="Bekal Aquatics Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Hospitality & Tourism</span>
                <div className="cs-header">
                  <h3>
                    Bekal Aquatics
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
                <Link to="/branding/nubinix/" className="home-cs-link last">
                    <div className="hcs-img">
                    <StaticImage
                        src="../../images/branding/nubinix/nubinix-listing-image.png"
                        alt="Kalahari Branding Project | WowMakers"
                        placeholder="blurred"
                        width={507}
                        height={354}
                        className="img-fluid"
                    />
                    </div>
                    <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>IT/Consulting</span>
                    <div className="cs-header">
                        <h3>
                            Nubinix
                        </h3>
                    </div>
                    <div className="btn wowaction-btn">
                        <ButtonIcon />
                        <span>Read More</span>
                    </div>
                    </div>
                </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/xhale/" className="home-cs-link first">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/xhale/xhale-listing-image.png"
                  alt="Xhale Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Health & Wellness</span>
                <div className="cs-header">
                  <h3>
                    Xhale
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/square-projects/" className="home-cs-link middle">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/square-projects/square-projects-listing-image.png"
                  alt="Square Projects Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Construction</span>
                <div className="cs-header">
                  <h3>
                    Square Projects
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
              <Link to="/branding/the-heritage-1866/" className="home-cs-link last">
                  <div className="hcs-img">
                  <StaticImage
                      src="../../images/branding/the-heritage-1866/heritage-1888-listing-image.png"
                      alt="The Heritage 1866 Branding Project | WowMakers"
                      placeholder="blurred"
                      width={507}
                      height={354}
                      className="img-fluid"
                  />
                  </div>
                  <div className="hcs-content branding">
                  <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Hospitality & Tourism</span>
                  <div className="cs-header">
                      <h3>
                        The Heritage 1866
                      </h3>
                  </div>
                  <div className="btn wowaction-btn">
                      <ButtonIcon />
                      <span>Read More</span>
                  </div>
                  </div>
              </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/bekal-ripples/" className="home-cs-link first">
              <div className="hcs-img">
                <StaticImage
                    src="../../images/branding/bekal-ripples/bekal-ripples-listing-image.png"
                    alt="Bekal Ripples Branding Project | WowMakers"
                    placeholder="blurred"
                    width={507}
                    height={354}
                    className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Hospitality & Tourism</span>
                <div className="cs-header">
                    <h3>
                        Bekal Ripples
                    </h3>
                </div>
                <div className="btn wowaction-btn">
                    <ButtonIcon />
                    <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
            <Link to="/branding/trinity-dex/" className="home-cs-link middle">
              <div className="hcs-img">
                <StaticImage
                  src="../../images/branding/trinity-dex/TrinityDexlistingimage.png"
                  alt="Trinity Dex Projects Branding Project | WowMakers"
                  placeholder="blurred"
                  width={507}
                  height={354}
                  className="img-fluid"
                />
              </div>
              <div className="hcs-content branding">
                <span className="section-tag">Branding<span className="seperator"><span>.</span></span>IT/Consulting</span>
                <div className="cs-header">
                  <h3>
                    TrinityDex
                  </h3>
                </div>
                <div className="btn wowaction-btn">
                  <ButtonIcon />
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={4} className="mt-lg-5 mt-3">
              <Link to="/branding/theyyam-trails/" className="home-cs-link last">
                  <div className="hcs-img">
                  <StaticImage
                      src="../../images/branding/theyyam-trails/theyyam-trails-listing-image.png"
                      alt="The Heritage 1866 Branding Project | WowMakers"
                      placeholder="blurred"
                      width={507}
                      height={354}
                      className="img-fluid"
                  />
                  </div>
                  <div className="hcs-content branding">
                  <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Hospitality & Tourism</span>
                  <div className="cs-header">
                      <h3>
                        Theyyam Trails
                      </h3>
                  </div>
                  <div className="btn wowaction-btn">
                      <ButtonIcon />
                      <span>Read More</span>
                  </div>
                  </div>
              </Link>
          </Col>                                                                                                                                                                 
        </Row>
      </Container>
    </section>
  )
}

export default BrandingListing
