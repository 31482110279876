import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import { PrevArrow, NextArrow } from "../global/customArrows";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../sass/global/testimonials.scss"
import "../../sass/global/slider.scss"

const BrandTestimonials = () => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false
      }
    },
    {
      breakpoint: 787,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    }
  ]

  }
  return (
    <section className="testimonials home">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">Clients Turned Brand Ambassadors</h2>
            <p className="sub-desc">
              We aim for more than happy clients—we create passionate advocates. See what sets our partnerships apart.
            </p>
          </Col>
          <Col md={12}>
            <Slider {...settings}>
              <div class="testimonial">
                <StaticImage
                  src="../../images/testimonials/james-joseph.jpeg"
                  alt=""
                  width={104}
                  height={104}
                  placeholder="blurred"
                />
                <div className="testimonial-content">
                  <p className="text">
                    “We selected WowMakers over competitors simply because
                    they combined quality, speed and excellent communication.
                    The project was handled brilliantly from start to finish”
                  </p>
                  <p className="c-name">
                    <span>James Joseph</span>
                  </p>
                  <p className="c-designtion">
                    Director of Executive Engagement, Microsoft
                  </p>
                </div>
              </div>              
              <div class="testimonial">
                <StaticImage
                  src="../../images/testimonials/reem-talib.jpeg"
                  alt=""
                  width={104}
                  height={104}
                  placeholder="blurred"
                />
                <div className="testimonial-content">
                  <p className="text">
                    “WowMakers conveyed the message that we have perfectly
                    through the videos and the website! We found them very
                    professional and creative. They knew exactly what we
                    wanted!”
                  </p>
                  <p className="c-name">
                    <span>Reem Talib</span>
                  </p>
                  <p className="c-designtion">
                    Director of Operations, Nomidman
                  </p>
                </div>
              </div>
              <div class="testimonial">
                <StaticImage
                  src="../../images/testimonials/soh-tanaka.jpeg"
                  alt=""
                  width={104}
                  height={104}
                  placeholder="blurred"
                />
                <div className="testimonial-content">
                  <p className="text">
                    “What really impressed me about their work is the pixel
                    perfection, quick turn around time and great
                    communication. I’d love to collab with them again in near
                    future”
                  </p>
                  <p className="c-name">
                    <span>Soh Tanaka</span>
                  </p>
                  <p className="c-designtion">
                    Product Designer at Oculus VR, Facebook
                  </p>
                </div>
              </div>            
              <div class="testimonial">
                <StaticImage
                  src="../../images/testimonials/simi.jpeg"
                  alt=""
                  width={104}
                  height={104}
                  placeholder="blurred"
                />
                <div className="testimonial-content">
                  <p className="text">
                    “Working with WowMakers was smooth and they completed the
                    project on time. We are more than happy to recommend their
                    service to any of our clients”
                  </p>
                  <p className="c-name">
                    <span>Simi</span>
                  </p>
                  <p className="c-designtion">
                    Head of Corporate Communications, AON
                  </p>
                </div>
              </div>
              <div class="testimonial">
                <StaticImage
                  src="../../images/testimonials/adam-chronister.jpeg"
                  alt=""
                  width={104}
                  height={104}
                  placeholder="blurred"
                />
                <div className="testimonial-content">
                  <p className="text">
                    “They are brilliant designers! I have the opportunity to
                    work with them on many projects and I’m always amazed by
                    their creativity and ability to deliver on time”
                  </p>
                  <p className="c-name">
                    <span>Adam Chronister</span>
                  </p>
                  <p className="c-designtion">
                    Strategist & Speaker, Featured on Wired.com
                  </p>
                </div>
              </div>
              <div class="testimonial">
                <StaticImage
                  src="../../images/testimonials/abhilasha-mathur.jpeg"
                  alt=""
                  width={104}
                  height={104}
                  placeholder="blurred"
                />
                <div className="testimonial-content">
                  <p className="text">
                    “Apart from the top notch quality of their work, what
                    really impressed me was how seamlessly we were able to
                    complete the project because of their solid process”
                  </p>
                  <p className="c-name">
                    <span>Abhilasha Mathur</span>
                  </p>
                  <p className="c-designtion">
                    Assistant VP of Marketing, HDFC Bank
                  </p>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )  
}

export default BrandTestimonials
