import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "../../sass/global/about-work.scss"

const BrandingAboutWork = () => {
    return (
        <section class="wow-work">
            <Container>
                <Row className="align-items-center">
                    <Col sm={6}>
                        <h2 className="wmw-left-content mobile-margin-left-right">
                            We elevate brands through digital innovation
                        </h2>                        
                    </Col>
                    <Col sm={6}>
                        <p className="wmw-right-content mobile-margin-left-right">
                            A digital agency blending creativity, technology, and AI to craft impactful brands, digital experiences, and videos across all platforms since 2011.
						</p>                        
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BrandingAboutWork